




















































import Vue from "vue";
import rootStore from "@/store";
import { HaeQuestion } from "@/types/hae";
import { HaeState } from "@/types/hae";
const state = rootStore.state as { hae: HaeState };

function screeningAnswers(): Array<number | number[] | undefined> {
  return state.hae.screeningAnswers.map(el => (el === null ? undefined : el));
}

function parseParamsAnswers(queryParams: string) {
  return JSON.parse(queryParams);
}

export default Vue.extend({
  name: "ResultBoxForThird",
  props: { question: Object as Vue.PropType<HaeQuestion> },
  computed: {
    answerIndexes(): number[] {
      const answers =
        typeof this.$route.query.answers === "string"
          ? parseParamsAnswers(this.$route.query.answers)
          : screeningAnswers();
      const answer = answers[this.question.id - 1];
      if (typeof answer === "number") {
        return [answer];
      }
      if (answer === undefined) {
        return [];
      }
      return answer;
    }
  },
  methods: {
    fetchAnswerBoxClassName(idx: number): string {
      const effective = this.question.effectiveChoices.includes(idx)
        ? "effective"
        : "";
      const wide = this.question.isWideAnswer ? "wide-answer" : "";
      return [effective, wide].join(" ");
    }
  }
});
