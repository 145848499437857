import { HaeQuestion } from "@/types/hae";
export function questions(): Array<HaeQuestion> {
  return [
    {
      id: 1,
      text: "あなたの腫れ・むくみは、突然あらわれて、数日で消えますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 2,
      text:
        "あなたの腫れ・むくみは、片足や片手、皮膚の一部など部分的に起こりますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 3,
      text:
        "腫れた部分を指で5~15秒ほど強く押したとき、へこんでもすぐに元に戻りますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 4,
      text:
        "蚊に刺されたときのように、ぷっくりとした境界明瞭な皮膚の膨らみや赤みはありますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [1]
    },
    {
      id: 5,
      text: "強いかゆみはありますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [1]
    },
    {
      id: 6,
      text: "腫れ、むくみはどのくらい続きますか?",
      supplyment: "(繰り返し発疹ができる場合は、それぞれの腫れが続く時間)",
      choices: ["数時間以内が多い(1日以内)", "2~3日続くことが多い"],
      exclusiveChoices: undefined,
      isMultiple: false,
      isWideAnswer: true,
      effectiveChoices: [1]
    },
    {
      id: 7,
      text:
        "腫れ・むくみは、からだのどこで起きたことがありますか?あてはまる場所をすべてチェックしてください。",
      choices: [
        "手や腕",
        "のど",
        "足(ふとももや膝から足首)",
        "おしりや外陰部",
        "顔(目の周り、唇)",
        "その他"
      ],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: true,
      isWideAnswer: true,
      effectiveChoices: [0, 1, 2, 4]
    },
    {
      id: 8,
      text: "これまでに、原因不明の腹痛を起こしたことがありますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 9,
      text:
        "これまでに、急に、唇、まぶた、舌、口の中、顔、首が大きくはれて、「のどのつまり」、「息苦しい(呼吸困難)」、「話しづらい」などと感じたことがありますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 10,
      text: "皮膚の腫れ・むくみ、腹痛は、何度か繰り返し起こっていますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 11,
      text:
        "ご家族に、あなたと同じような腫れ・むくみ、腹痛を経験したことのある方はいますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 12,
      text:
        "ご家族に、命にかかわるような呼吸困難を経験したことのある方はいますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 13,
      text:
        "こんなときに、腫れ・むくみが起こったことがありますか?あてはまる場合をすべてチェックしてください。",
      choices: [
        "ケガをしたり抜歯した後",
        "精神的ストレスや疲労がたまったとき",
        "妊娠中や生理のとき",
        "薬(血圧を下げる薬、女性ホルモン剤など)を服用しているとき"
      ],
      exclusiveChoices: ["あてはまらない"],
      supplyment: undefined,
      isMultiple: true,
      isWideAnswer: true,
      effectiveChoices: [0, 1, 2, 3]
    },
    {
      id: 14,
      text: "腫れ・むくみは、何歳ごろから始まりましたか?",
      choices: ["10歳未満", "10~20歳", "20~30歳", "30~40歳", "40歳以上"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: true,
      effectiveChoices: [0, 1, 2, 3, 4]
    },
    {
      id: 15,
      text:
        "腫れ・むくみや腹痛が起こる前に、なにか前兆(だるさ、チクチク感、吐き気、発疹など)を感じますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: [0]
    },
    {
      id: 16,
      text:
        "腫れ・むくみを治療したとき、お薬(抗ヒスタミン薬、エピネフリン、ステロイド)が効かなかったことはありますか?",
      choices: ["はい", "いいえ", "不明、治療したことがない"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: true,
      effectiveChoices: [0]
    },
    {
      id: 17,
      text:
        "これまでに、じんま疹や花粉症、喘息などのアレルギー症状やアナフィラキシーショックを起こしたことがありますか?",
      choices: ["はい", "いいえ"],
      exclusiveChoices: undefined,
      supplyment: undefined,
      isMultiple: false,
      isWideAnswer: false,
      effectiveChoices: []
    },
    {
      id: 18,
      text:
        "現在、服用している薬がありますか? あてはまる薬があれば、すべてチェックください。",
      choices: ["女性ホルモン剤", "血圧を下げる薬", "NSAIDs"],
      exclusiveChoices: ["ない"],
      supplyment: "※ NSAIDs：非ステロイド性抗炎症薬（解熱鎮痛薬）",
      isMultiple: true,
      isWideAnswer: true,
      effectiveChoices: [0, 1, 2]
    }
  ];
}
