








export default {
  name: "QuoteOrigin"
};
