<template>
  <div class="hae-third-result">
    <img
      alt="title logo"
      src="../../assets/hae/result3.svg"
      class="topset-logo"
    />

    <div class="white-box">
      <div class="margin-block">
        <p class="subject-text bright-color">
          あなたは「腫れ・むくみをくり返す体質」の可能性があります
        </p>
      </div>
      <div class="title-box-inside">
        <p class="heading-text-inside">チェックシート</p>
      </div>
      <div class="margin-block">
        <p class="bold-notice">
          医師による正しい診断を受けることをお勧めします。
        </p>

        <ul class="caution-list notice-text">
          <li>
            本チェックシートは「腫れ・むくみをくり返す体質」の、特徴的な症状に基づいて作成されています。
          </li>
          <li>
            <mark class="with-background">背景に色が付いている回答</mark
            >が「腫れ・むくみをくり返す体質」に関係する可能性がある項目です。
          </li>
          <li>
            正しい診断を受けるためには必ず医師による診察を受けてください。
          </li>
        </ul>
        <p class="selection-title">腫れ・むくみのタイプ</p>
        <ResultBoxForThird
          v-for="q in questions.slice(0, 1)"
          :key="q.id"
          :question="q"
        />
        <div
          v-if="!isOpenedMoreResult"
          class="more-button"
          @click="displayMore()"
        >
          すべてを表示する
          <b-icon icon="chevron-down" class="more-icon"></b-icon>
        </div>
        <div v-if="isOpenedMoreResult">
          <ResultBoxForThird
            v-for="q in questions.slice(1, 3)"
            :key="q.id"
            :question="q"
          />
          <p class="selection-title">じんま疹との区別</p>
          <ResultBoxForThird
            v-for="q in questions.slice(3, 6)"
            :key="q.id"
            :question="q"
          />
          <p class="selection-title">「遺伝的に腫れやすい体質」を疑う症状</p>
          <ResultBoxForThird
            v-for="q in questions.slice(6, 18)"
            :key="q.id"
            :question="q"
          />
        </div>

        <br />
        <p class="subject-text">セルフチェック結果を<br />医師に見てもらう</p>
        <p class="text-block" style="text-align: center;">
          セルフチェックの結果を添付して、医師に相談ができます。結果をもとに受診の必要性や病気の可能性を確認してみましょう。
        </p>
        <div v-if="isLogined">
          <a
            class="lineless"
            @click="
              postTopicDraft();
              sendGa('click', 'hae', 'add-topics-from-third-result');
            "
          >
            <CommonButton text="さっそく相談する" styleType="active" />
          </a>
        </div>
        <div v-else>
          <a
            class="lineless"
            @click="
              isLoginModalActive = true;
              sendGa('click', 'hae', 'add-topic-without-login');
            "
          >
            <CommonButton
              text="AskDoctorsで<br/>結果を添付して医師に相談"
              styleType="active"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="white-box">
      <div class="margin-block">
        <p class="subject-text">
          HAE専門医がいる医療機関を探す
        </p>
        <p class="text-block" style="text-align: center;">
          HAE(遺伝性血管性浮腫)について相談できる病院を検索
        </p>
        <a
          href="https://www.qlifeweb.jp/hae/"
          class="lineless"
          @click="sendGa('click', 'hae', 'hospital-search')"
        >
          <CommonButton text="医療機関を探す" styleType="blue" />
        </a>
        <p class="notice-text">※外部サイトに遷移します</p>
      </div>
    </div>

    <QuoteOrigin />

    <b-modal v-model="isLoginModalActive" :width="320" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p class="login-text">
              AskDoctorsのログインが必要です。
            </p>
            <a
              v-bind:href="fetchLoginUrl"
              v-if="!isLogined"
              class="lineless"
              @click="sendGa('click', 'hae', 'login-from-third-resutl')"
            >
              <CommonButton text="会員の方はログイン" styleType="white" />
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import rootStore from "@/store";
import { questions } from "../../data/hae";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
import CommonButton from "@/components/hae/CommonButton.vue";
import ResultBoxForThird from "@/components/hae/ResultBoxForThird.vue";
const state = rootStore.state;
export default {
  name: "HaeThirdResult",
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  components: { QuoteOrigin, CommonButton, ResultBoxForThird },
  data: function() {
    return {
      questions: questions(),
      isLoginModalActive: false,
      isOpenedMoreResult: false
    };
  },
  created: function() {
    if (this.$route.query.answers !== undefined) {
      this.isOpenedMoreResult = true;
    }
  },
  beforeCreate: function() {
    this.$store.commit("hae/changeUserInfo", {
      patientId: undefined,
      userStatus: undefined
    });
    if (this.$route.query.answers !== undefined) {
      return;
    }
    Promise.all([
      this.axios.post(
        process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/hae_answers",
        {
          version_tag: "v01",
          answer1: JSON.stringify(state.hae.screeningAnswers[0]) || "null",
          answer2: JSON.stringify(state.hae.screeningAnswers[1]) || "null",
          answer3: JSON.stringify(state.hae.screeningAnswers[2]) || "null",
          answer4: JSON.stringify(state.hae.screeningAnswers[3]) || "null",
          answer5: JSON.stringify(state.hae.screeningAnswers[4]) || "null",
          answer6: JSON.stringify(state.hae.screeningAnswers[5]) || "null",
          answer7: JSON.stringify(state.hae.screeningAnswers[6]) || "null",
          answer8: JSON.stringify(state.hae.screeningAnswers[7]) || "null",
          answer9: JSON.stringify(state.hae.screeningAnswers[8]) || "null",
          answer10: JSON.stringify(state.hae.screeningAnswers[9]) || "null",
          answer11: JSON.stringify(state.hae.screeningAnswers[10]) || "null",
          answer12: JSON.stringify(state.hae.screeningAnswers[11]) || "null",
          answer13: JSON.stringify(state.hae.screeningAnswers[12]) || "null",
          answer14: JSON.stringify(state.hae.screeningAnswers[13]) || "null",
          answer15: JSON.stringify(state.hae.screeningAnswers[14]) || "null",
          answer16: JSON.stringify(state.hae.screeningAnswers[15]) || "null",
          answer17: JSON.stringify(state.hae.screeningAnswers[16]) || "null",
          answer18: JSON.stringify(state.hae.screeningAnswers[17] || "null")
        },
        { withCredentials: true }
      ),
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info",
          {
            withCredentials: true
          }
        )
        .then(res => {
          this.$cookies.set("patient_id", res.data.patientId, "24h");
          this.$store.commit("hae/changeUserInfo", {
            patientId: res.data.patientId,
            userStatus: res.data.userStatus
          });
        })
    ]).catch(error => {
      console.error(error);
    });
  },
  computed: {
    isLogined() {
      return (
        state.hae.userStatus === "charged" || state.hae.userStatus === "free"
      );
    },
    fetchRedirectUrl() {
      return encodeURIComponent(
        process.env.VUE_APP_BASE_URL + "hae/third_result"
      );
    },
    fetchChargedRegisterUrl() {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "introduction?return_to=" +
        this.fetchRedirectUrl
      );
    },
    fetchLoginUrl() {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "login?return_to=" +
        this.fetchRedirectUrl
      );
    }
  },
  methods: {
    displayMore() {
      this.isOpenedMoreResult = true;
    },
    postTopicDraft() {
      const params = encodeURIComponent(
        JSON.stringify(state.hae.screeningAnswers)
      );
      const resultUrl =
        process.env.VUE_APP_BASE_URL + "hae/third_result?answers=" + params;

      Promise.all([
        this.axios.post(
          process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/drafts",
          { title: "HAEセルフチェックの結果について", body: resultUrl },
          { withCredentials: true }
        )
      ])
        .then(res => {
          document.location.href =
            res[0].data.topic_draft_url + "&free_post_title=HAEセルフチェック";
        })
        .catch(error => {
          //もし接続の問題とかで取得が失敗した場合でもfallbackとしてisLoading=false/userStatus=not_login
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
@import "../../assets/style/hae.css";

.subject-text {
  font-size: 24px;
  line-height: 28px;
  margin: 10px;
  font-weight: bold;
}
.bright-color {
  color: #ff8900;
}

.margin-block {
  padding: 20px;
}

.title-box-inside {
  background: #28c8a0;
  margin: 0 auto;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-text-inside {
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  padding: 10px 10px;
  color: #fff;
}

.white-box {
  padding: 0;
}

p.selection-title {
  border-bottom: 1px solid #b2d6ef;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  padding: 1rem 0;
  line-height: 100%;
  margin-top: 20px;
}

.bold-notice {
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;

  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.with-background {
  background-color: #b2d6ef;
}

.more-button {
  padding: 16px;
  padding-left: 40px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  color: #004da1;
  border: 2px solid #004da1;
  background: #fff;
  text-align: center;
}

.more-icon {
  font-size: 24px;
  float: right;
}
</style>
